import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import './SideNav.css';
import { useTheme } from '../ThemeContext/ThemeContext';
import { useAuth } from '../../AuthContext';

const SideNav = ({ isVisible, onClose, onCategoryAdd, onTodoUpdate, categories = [], todos, handleCategoryRename, openModal }) => {
  const [newCategory, setNewCategory] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [isEditingName, setIsEditingName] = useState(false);
  const { displayName, updateDisplayName } = useAuth();
  const [newDisplayName, setNewDisplayName] = useState(displayName);

  const handleCreateCategoryClick = () => {
    setShowInput(!showInput);
    setNewCategory('');
  };

  const handleInputChange = (e) => {
    setNewCategory(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter' && newCategory.trim() !== '') {
      const updatedCategories = [...categories, { name: newCategory, count: 0 }];
      onCategoryAdd(updatedCategories);
      setNewCategory('');
      setShowInput(false);
    }
  };

  const handleDeleteCategory = (index) => {
    const categoryName = categories[index].name;
    const updatedCategories = categories.filter((_, i) => i !== index);
    onCategoryAdd(updatedCategories);

    const updatedTodos = { ...todos };
    delete updatedTodos[categoryName];
    onTodoUpdate(updatedTodos);
  };

  const handleEditCategory = (index) => {
    setEditIndex(index);
  };

  const handleEditChange = (e, index) => {
    const oldCategoryName = categories[index].name;
    const newCategoryName = e.target.value;

    handleCategoryRename(oldCategoryName, newCategoryName);

    const updatedCategories = [...categories];
    updatedCategories[index].name = newCategoryName;
    onCategoryAdd(updatedCategories);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(categories);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onCategoryAdd(items);
  };

  const handleDisplayNameChange = (e) => {
    setNewDisplayName(e.target.value);
  };

  const handleDisplayNameKeyPress = async (e) => {
    if (e.key === 'Enter' && newDisplayName.trim() !== '') {
      await updateDisplayName(newDisplayName);
      setIsEditingName(false);
    }
  };

  const { theme } = useTheme();

  const placardImages = {
    default: [
      '/images/task-one-placard.png',
      '/images/task-two-placard.png',
      '/images/task-three-placard.png'
    ],
    darkCave: [
      'category-page-placard-1.png',
      'category-page-placard-2.png',
      'category-page-placard-3.png'
    ],
    swamp: [
      'acid-placard-1.png',
      'acid-placard-2.png',
      'acid-placard-3.png'
    ],
    bloodTundra: [
      'blood-placard-1.png',
      'blood-placard-2.png',
      'blood-placard-3.png'
    ],
    palace: [
      'palace-placard-1.png',
      'palace-placard-2.png',
      'palace-placard-3.png'
    ],
    fifthCircle: [
      'fifth-circle-placard-1.png',
      'fifth-circle-placard-2.png',
      'fifth-circle-placard-3.png'
    ],
  };

  const currentPlacardImages = placardImages[theme] || placardImages.default;

  return (
    <div className={`side-nav ${isVisible ? 'visible' : ''}`}>
      <button className="close-btn" onClick={onClose}>X</button>
      <div className='user-container'>
        <img className='avatar-icon' alt='avatar-icon-alt' src='/images/avatar-satan-app.png' onClick={() => openModal(false)} />
        {isEditingName ? (
          <input
            type="text"
            className="user-edit-input"
            value={newDisplayName}
            onChange={handleDisplayNameChange}
            onKeyPress={handleDisplayNameKeyPress}
            onBlur={() => setIsEditingName(false)}
            autoFocus
          />
        ) : (
          <div className="user-display-name">
            <span className='user-styles'>{displayName}</span>
            <img
              src='/images/edit-satan.png'
              alt='edit'
              className='edit-icon-user'
              onClick={() => setIsEditingName(true)}
            />
          </div>
        )}
        <img className='search-icon' alt='search-icon-alt' src='/images/search-symbol-satan-app.png' />
      </div>
      
      <Link className='other-drop-down-styles' to="/" onClick={onClose}>My Day</Link>
      <Link className='other-drop-down-styles' to="/calendar" onClick={onClose}>Calendar</Link>
      <Link className='other-drop-down-styles' to="/upcoming" onClick={onClose}>Upcoming</Link>
      <Link className='other-drop-down-styles' to="/vault" onClick={onClose}>Vault</Link> {/* New Vault link */}
      <Link className='other-drop-down-styles' to="/graveyard" onClick={onClose}>Graveyard</Link>
      <a className='other-drop-down-styles' onClick={handleCreateCategoryClick} role="button" tabIndex="0">Create Category</a>

      <img className='separator-line-satan-image' src='/images/separator-line-satan.png' alt='separator-line-satan-alt' />
      {showInput && (
        <input
          type="text"
          className="category-input"
          value={newCategory}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          placeholder="enter category name"
        />
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="categories">
          {(provided) => (
            <div
              className="categories-list"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {categories.map((category, index) => (
                <Draggable key={index} draggableId={index.toString()} index={index}>
                  {(provided) => (
                    <div
                      className='category-container'
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {editIndex === index ? (
                        <input
                          type="text"
                          className="category-edit-input"
                          value={category.name}
                          onChange={(e) => handleEditChange(e, index)}
                          autoFocus
                          onBlur={() => setEditIndex(-1)}
                        />
                      ) : (
                        <>
                          <Link className='other-drop-down-styles' to={`/category/${category.name}`} onClick={onClose}>
                            {category.name}
                          </Link>
                          <div className="category-count">{category.count}</div>
                          <img
                            src='/images/edit-satan.png'
                            alt='edit'
                            className='edit-icon'
                            onClick={() => handleEditCategory(index)}
                          />
                          <img
                            src='/images/garbage-black.png'
                            alt='delete'
                            className='delete-icon'
                            onClick={() => handleDeleteCategory(index)}
                          />
                        </>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default SideNav;
