import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Graveyard.css';
import { useTheme } from '../ThemeContext/ThemeContext';
import SatanSpeaks from '../SatanSpeaks/SatanSpeaks';

const Graveyard = ({ todos, handleHamburgerClick }) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const generateCalendarDays = (month, year) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayIndex = new Date(year, month, 1).getDay();
    const calendarDays = [];
    for (let i = 0; i < firstDayIndex; i++) {
      calendarDays.push('');
    }
    for (let i = 1; i <= daysInMonth; i++) {
      calendarDays.push(i);
    }
    return calendarDays;
  };

  const calendarDays = generateCalendarDays(currentMonth, currentYear);

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const handlePreviousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleDayClick = (day) => {
    if (day) {
      const selectedDate = new Date(currentYear, currentMonth, day + 1); // Adjusted day by adding 1
      const formattedDate = selectedDate.toISOString().split('T')[0];
      navigate(`/graveyard/${formattedDate}`);
    }
  };

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  return (
    <div className={`app-container ${theme}-theme graveyard-page`}>
      <div className="my-day-container"><h1 className="my-day">Graveyard</h1></div>
      <div className='graveyard-content-container'>
        <div className="graveyard-header">
          <img src="/images/left-button-satan.png" className="arrow-button-left-arrow-graveyard" alt="Left Arrow" onClick={handlePreviousMonth} />
          <div className="month-title">
            {monthNames[currentMonth]}
            <div className="year">{currentYear}</div>
          </div>
          <img src="/images/right-button-satan.png" className="arrow-button-right-arrow-graveyard" alt="Right Arrow" onClick={handleNextMonth} />
        </div>
        <div className="graveyard-grid">
          {daysOfWeek.map(day => (
            <div key={day} className="graveyard-day-header">{day}</div>
          ))}
          {calendarDays.map((day, index) => (
            <div
              key={index}
              className={`graveyard-day ${day === today.getDate() && currentMonth === today.getMonth() && currentYear === today.getFullYear() ? 'current-day' : ''}`}
              onClick={() => handleDayClick(day)}
            >
              {day && <img src={`/images/tombstone-${day}.png`} alt={`Tombstone ${day}`} className="tombstone-image" />}
              <span className="day-number">{day}</span>
            </div>
          ))}
        </div>
      </div>
      <footer className='footer-container'>
        <div className='hamburger-icon' onClick={handleHamburgerClick}>
          <img className='hamburger-icon-image' src="/images/hamburger-sign.png" alt="hamburger-icon-footer-nav" />
        </div>
        <SatanSpeaks />
      </footer>
    </div>
  );
};

export default Graveyard;
