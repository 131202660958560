import "./StockPlaccard.css";
import StockName from "./StockName";
import StockGraph from "./StockGraph";
import StockPercent from "./StockPercent";

function StockPlaccard() {
    return (
        <div className="stock-placcard">
            <div className="left-side">
                <div className="stock-name">
                    <StockName />
                </div>
            </div>
            <div className="right-side">
                <div className="graph-container">
                    <StockGraph />
                </div>
                <div className="numbers-container">
                    <div className="percentage">
                        <StockPercent />
                    </div>
                    <div className="total"></div>
                </div>
            </div>
        </div>
    );
}

export default StockPlaccard;