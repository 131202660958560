import './Weather.css';
import { useState, useEffect } from 'react';

function WeatherTemp() {
    const [tempContent, setTempContent] = useState(0);

    useEffect(() => {
        const temp = Math.floor(Math.random() * 200) + 100;
        setTempContent(temp);
    }, []);

    return (
        <div className='weather-temp-text'>{tempContent}°</div>
    );
};

export default WeatherTemp;