// Weather.js
import './Weather.css';
import WeatherTemp from './WeatherTemp';
import WeatherDes from './WeatherDes';
import WeatherPics from './WeatherPics';
import { useState, useEffect } from 'react';


function Weather() {
    const [selectedWeather, setSelectedWeather] = useState({
        pic: '',
        description: ''
    });

    useEffect(() => {
        const weatherData = [
            { pic: '/images/blood-cloud.png', description: 'Blood\nClouds' },
            { pic: '/images/partly-sunny.png', description: 'Scorching' },
            { pic: '/images/raining-blood.png', description: 'Raining Blood' },
            { pic: '/images/raining-organs.png', description: 'Raining Organs' },
            { pic: '/images/sunny.png', description: "Everyone's melting" },
            { pic: '/images/acid-rain.png', description: "Acid Rain" },
            { pic: '/images/fire-tornado.png', description: "Fire Tornado" },
            { pic: '/images/hell-fire-rain.png', description: "Molten Hail" },
            { pic: '/images/locusts.png', description: "Mostly Locusts" },
            { pic: '/images/blood-moon-eclipse.png', description: "Blood Moon Eclipse" },
            { pic: '/images/demonic-lightning.png', description: "Demonic Lightning" },
        ];

        const randomIndex = Math.floor(Math.random() * weatherData.length);
        setSelectedWeather(weatherData[randomIndex]);
    }, []);

    return (
        <div className='weather-container'>
            <div className='icon-container'>
                <WeatherPics weatherPic={selectedWeather.pic} />
            </div>
            <div className='temp-and-des-container'>
                <div className='temp-container'>
                    <WeatherTemp />
                </div>
                <div className='des-container'>
                    <WeatherDes description={selectedWeather.description} />
                </div>
            </div>
        </div>
    );
}

export default Weather;
