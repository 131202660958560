import "./Weather.css";
import Weather from "./Weather";
import { useState } from "react";

function WeatherButton() {
    const [showWeather, setShowWeather] = useState(false);

    function handleClick() {
        setShowWeather(prevShowWeather => !prevShowWeather);
    };

    return(
        <div>
            <img 
            src="/images/weather-button-icon-small.png" 
            className="weather-button" 
            onClick={handleClick}
            alt="Weather Button"
            />
            {showWeather && <Weather />}
        </div>
    );
};

export default WeatherButton;