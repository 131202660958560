// src/components/SignUp/SignUp.js

import React, { useState } from 'react';
import { useAuth } from '../../AuthContext';
import './SignUp.css';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signUp, currentUser, signOut } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signUp(email, password);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="signup-container">
      <h2>Sign Up</h2>
      {currentUser ? (
        <div>
          <p>You are signed in as {currentUser.email}</p>
          <button className="sign-out-button" onClick={signOut}>Sign Out</button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="sign-up-button" type="submit">Sign Up</button>
        </form>
      )}
    </div>
  );
};

export default SignUp;
