import { useState, useEffect } from "react";
import "./StockGraph.css"

function StockGraph() {
    const [graphType, setGraphType] = useState("")

    useEffect(() => {
        const graphTypeList = [
            "/images/red-chart-1.png",
            "/images/red-chart-2.png",
            "/images/red-chart-3.png",
            "/images/red-chart-4.png",
            "/images/green-chart-1.png",
            "/images/green-chart-2.png",
            "/images/green-chart-3.png",
            "/images/green-chart-4.png"
        ];

        const randomIndex = Math.floor(Math.random() * graphTypeList.length);
        const randomGraphType = graphTypeList[randomIndex];
        setGraphType(randomGraphType);

    }, []);

    return (
        <div className="graph-container">
            <img className="graph" src={graphType} alt="graph-type" />
        </div>
    );
}

export default StockGraph;