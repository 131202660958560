import { useState, useEffect } from "react";
import "./ScoreMaker.css";

function ScoreMaker() {
  
  const [score, setScore] = useState(() => Math.floor(Math.random() * 40) + 1); 

  useEffect(() => {
    
    const interval = setInterval(() => {
      setScore(prevScore => prevScore + Math.floor(Math.random() * 3)); 
    }, Math.random() * 10000 + 1000); 

    return () => clearInterval(interval); 
  }, []);

  return (
    <div className="score-container">
      {String(score).split('').map((digit, index) => (
        <span className="score-digit" key={index}>
          {digit}
        </span>
      ))}
    </div>
  );
}

export default ScoreMaker;
