import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ThemeSwitcher from '../ThemeSwitcher/ThemeSwitcher';
import Scroll from '../Scroll/Scroll';
import SideNav from '../SideNav/SideNav';
import './Vault.css';
import SatanSpeaks from '../SatanSpeaks/SatanSpeaks.js';

const Vault = ({ scrolls, setScrolls, categories, handleCategoryRename, todos, onTodoUpdate, showSideNav, handleHamburgerClick }) => {
  const [newScroll, setNewScroll] = useState('');

  const handleAddScroll = () => {
    if (newScroll.trim() !== '') {
      const scrollImages = [
        '/images/scroll-1.png',
        '/images/scroll-2.png',
        '/images/scroll-3.png',
        '/images/scroll-4.png',
        '/images/scroll-5.png'
      ];
      const newScrollItem = {
        id: scrolls.length + 1,
        name: newScroll,
        image: scrollImages[scrolls.length % scrollImages.length],
        paper: `paper-${(scrolls.length % scrollImages.length) + 1}.jpg`
      };
      setScrolls([...scrolls, newScrollItem]);
      setNewScroll('');
    }
  };

  const handleDeleteScroll = (id) => {
    setScrolls(scrolls.filter(scroll => scroll.id !== id));
  };

  const handleRenameScroll = (id, newName) => {
    const updatedScrolls = scrolls.map(scroll =>
      scroll.id === id ? { ...scroll, name: newName } : scroll
    );
    setScrolls(updatedScrolls);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(scrolls);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setScrolls(items);
  };

  return (
    <div className="vault-page">
      <SideNav
        isVisible={showSideNav}
        onClose={handleHamburgerClick}
        categories={categories}
        todos={todos}
        handleCategoryRename={handleCategoryRename}
        onTodoUpdate={onTodoUpdate}
      />
      <ThemeSwitcher />
      <div className="vault-content">
        <input
          type="text"
          value={newScroll}
          onChange={(e) => setNewScroll(e.target.value)}
          className="vault-input"
          placeholder="Name your scroll..."
        />
        <button onClick={handleAddScroll} className="add-scroll-button">Add Scroll</button>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="scrolls">
            {(provided) => (
              <div className="scrolls-container" {...provided.droppableProps} ref={provided.innerRef}>
                {scrolls.map((scroll, index) => (
                  <Draggable key={scroll.id} draggableId={scroll.id.toString()} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="draggable-scroll"
                      >
                        <Scroll 
                          key={scroll.id} 
                          scroll={scroll} 
                          handleDeleteScroll={handleDeleteScroll}
                          handleRenameScroll={handleRenameScroll}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <footer className="footer-container">
        <div className='hamburger-icon' onClick={handleHamburgerClick}>
          <img className='hamburger-icon-image' src="/images/hamburger-sign.png" alt="hamburger-icon-footer-nav" />
        </div>
        <SatanSpeaks />
      </footer>
    </div>
  );
};

export default Vault;
