import { useState, useEffect } from "react";
import "./StockName.css";

function StockName() {
    const [stockName, setStockName] = useState({
        name: " ",
        acronym: " "
    });

    const [previousStockIndex, setPreviousStockIndex] = useState(null); // Track the previous stock index

    useEffect(() => {
        const stockNameList = [
            { name: "Demon Overlords of Monetary Exchange", acronym: "(DOOM)" },
            { name: "Brimstone Stock Index", acronym: "(BRNSTK)" },
            { name: "Sulphur 500 ", acronym: "(SULPH500)" },
            { name: "Hellish Enterprises Ledger Exchange", acronym: "(HELLX)" },
            { name: "Abyssal Capital & Commerce", acronym: "(ABYSS)" },
            { name: "Dissecting Internal Exchange", acronym: "(DIE)" },
            { name: "Death and Mutilation Network", acronym: "(DAMN)" },
            { name: "Suffication Holdings", acronym: "(SFFCT)" },
            { name: "Acid Burn Monetary Metric", acronym: "(ACID)" },
            { name: "Hemorrhaging Heathens Exchange", acronym: "(HEMR)" },

        ];

        const getRandomStock = () => {
            let randomIndex;
            do {
                randomIndex = Math.floor(Math.random() * stockNameList.length);
            } while (randomIndex === previousStockIndex); 
            
            setPreviousStockIndex(randomIndex); 
            return stockNameList[randomIndex]; 
        };

        setStockName(getRandomStock());
    }, []); 

    return (
        <div className="stock-name">
            {stockName.name}
            <div className="divider"></div>
            <div className="acronym">
                {stockName.acronym}
            </div>
        </div>
    );
}

export default StockName;
