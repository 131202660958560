// src/components/Modal/Modal.js

import React, { useState } from 'react';
import { useAuth } from '../../AuthContext';
import './Modal.css';
import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SignUp';

const Modal = ({ show, onClose }) => {
  const [isSignIn, setIsSignIn] = useState(true);
  const { currentUser, signOut } = useAuth();

  const switchAuthMode = () => {
    setIsSignIn(!isSignIn);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2 className="modal-title">Authentication</h2>
        </div>
        <div className="modal-body">
          {currentUser ? (
            <div>
              <p>You are signed in {currentUser.email}</p>
              <button className="button" onClick={signOut}>Sign Out</button>
            </div>
          ) : isSignIn ? <SignIn /> : <SignUp />}
          {!currentUser && (
            <div className="auth-switch">
              {isSignIn ? (
                <p>
                  Don't have an account?{' '}
                  <button className="switch-button" onClick={switchAuthMode}>
                    Sign Up
                  </button>
                </p>
              ) : (
                <p>
                  Already have an account?{' '}
                  <button className="switch-button" onClick={switchAuthMode}>
                    Sign In
                  </button>
                </p>
              )}
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button onClick={onClose} className="button">Close</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
