import { useState, useEffect } from "react";

function GameTime() {
    const generateRandomInitialTime = () => {
        const randomMinutes = Math.floor(Math.random() * 50); 
        const randomSeconds = Math.floor(Math.random() * 60); 
        return { randomMinutes, randomSeconds };
    };

    const { randomMinutes, randomSeconds } = generateRandomInitialTime();

    const [minutes, setMinutes] = useState(randomMinutes);
    const [seconds, setSeconds] = useState(randomSeconds);
    const [gameQuarter, setGameQuarter] = useState("3rd Quarter"); 

    useEffect(() => {
      
        const gameTimeList = [
            "3rd Quarter",
            "4th Slaughtering",
            "2nd Round of Beheadings",
            "18th Period of Blood",
            "Top of the 41st Acid Inning",
            "7th Wave of Locusts",
            "128th Mutilation, Round 3",
            "8th Lava Spill, Period 7"
        ];

        const randomIndex = Math.floor(Math.random() * gameTimeList.length);
        setGameQuarter(gameTimeList[randomIndex]);

        const interval = setInterval(() => {
            setSeconds(prevSeconds => {
                if (prevSeconds === 59) {
                    setMinutes(prevMinutes => prevMinutes + 1); 
                    return 0; 
                }
                return prevSeconds + 1;
            });
        }, 1000); 

        return () => clearInterval(interval); 
    }, []);

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return (
        <div>
            {gameQuarter} {formattedMinutes}:{formattedSeconds}
        </div>
    );
}

export default GameTime;
