import "./Stocks.css"
import StockPlaccard from "./StockPlaccard";
import ArrowDisplay from "./ArrowDisplay";

function Stocks() {
    return (
        <div className="parent-div">
            <div className="headline-container">
                <ArrowDisplay />
                <div className="stocks-headline">Infernal Exchange (INEX)</div>
            </div>
            <StockPlaccard />
            <StockPlaccard />
            <StockPlaccard />
        </div>
    );
}

export default Stocks;