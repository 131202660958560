import React from 'react';
import './Account_button.css';

function AccountButton({ openModal }) {
    return (
        <div onClick={openModal} className="log-in-button">
            Log in &#8226; Sign up
        </div>
    );
};

export default AccountButton;