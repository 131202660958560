import { useState, useEffect } from "react";
import "./StockPercent.css";

function StockPercent() {
    const [percent, setPercent] = useState(0); 
    const [isNegative, setIsNegative] = useState(false); 

    // Function to generate a random initial percentage
    const generateRandomPercent = () => {
        const randomValue = (Math.random() * 99.98 + 0.01).toFixed(2); 
        return parseFloat(randomValue);
    };

    useEffect(() => {
        const initialPercent = Math.random() > 0.5 ? generateRandomPercent() : -generateRandomPercent();
        setPercent(initialPercent);
        setIsNegative(initialPercent < 0);

        const interval = setInterval(() => {
            setPercent((prevPercent) => {
                const change = (Math.random() * 0.5).toFixed(2); 
                const newPercent = Math.random() > 0.5 ? prevPercent + parseFloat(change) : prevPercent - parseFloat(change);
                setIsNegative(newPercent < 0);

                return newPercent;
            });
        }, Math.random() * 5000 + 2000);

        return () => clearInterval(interval);
    }, []);

    const formattedPercent = `${isNegative ? "-" : "+"}${Math.abs(percent).toFixed(2)}%`;

    return (
        <div className={`heading ${isNegative ? "negative" : "positive"}`}>
            {formattedPercent}
        </div>
    );
}

export default StockPercent;
