import { useState, useEffect } from "react";
import "./TeamPics.css";

function Stadium() {
    const [stadiumSelect, setStadiumSelect] = useState("");

    useEffect(() => {
        const stadiumList = [
            "/images/toxic-pit.jpg",
            "/images/titans-forge.jpg",
            "/images/scalding-crater.jpg",
            "/images/pyre-of-perdition.jpg",
            "/images/obsidian-hollow.jpg",
            "/images/emberdome.jpg",
            "/images/brimstone.jpg",
            "/images/void-stadium.jpg"
        ];

        const randomIndex = Math.floor(Math.random() * stadiumList.length);
        const randomStadium = stadiumList[randomIndex];
        setStadiumSelect(randomStadium);

    }, []);

    return (
        <div>
            <img className="arena-pic-styles" src={stadiumSelect} alt="void-stadium-alt" />
        </div>
    );
}

export default Stadium;