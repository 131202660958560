import './Weather.css';

function WeatherPics({ weatherPic }) {
    return (
        <div className='weather-pic-holder'>
            <img src={weatherPic} alt='Weather condition' />
        </div>
    );
}

export default WeatherPics;