import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Scroll.css';

const Scroll = ({ scroll, handleDeleteScroll, handleRenameScroll }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(scroll.name);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const handleRenameClick = () => {
    setIsEditing(true);
  };

  const handleDeleteClick = () => {
    setShowDeletePrompt(true);
  };

  const handleRenameKeyPress = (e) => {
    if (e.key === 'Enter' && newName.trim() !== '') {
      handleRenameScroll(scroll.id, newName);
      setIsEditing(false);
    }
  };

  const handleDeleteConfirm = (confirm) => {
    if (confirm) {
      handleDeleteScroll(scroll.id);
    }
    setShowDeletePrompt(false);
  };

  return (
    <div className="scroll" style={{ backgroundImage: `url(${scroll.image})` }}>
      {isEditing ? (
        <input
          type="text"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          onKeyPress={handleRenameKeyPress}
          autoFocus
          className="scroll-edit-input"
        />
      ) : (
        <Link to={`/scroll/${scroll.id}`}>
          <h2 className="scroll-title">{scroll.name}</h2>
        </Link>
      )}
      <img
        src="/images/edit-satan.png"
        alt="edit"
        className="edit-icon-scroll"
        onClick={handleRenameClick}
      />
      <img
        src="/images/garbage-black.png"
        alt="delete"
        className="delete-icon-scroll"
        onClick={handleDeleteClick}
      />
      {showDeletePrompt && (
        <div className="delete-prompt">
          <p>Are you sure?</p>
          <button onClick={() => handleDeleteConfirm(true)}>Yes</button>
          <button onClick={() => handleDeleteConfirm(false)}>No</button>
        </div>
      )}
    </div>
  );
};

export default Scroll;
