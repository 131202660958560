import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from '../ThemeContext/ThemeContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './CategoryTodos.css';
import SatanSpeaks from '../SatanSpeaks/SatanSpeaks';
import ThemedTodoItem from '../ThemedTodoItem/ThemedTodoItem';

const placardImages = {
  default: [
    '/images/task-one-placard.png',
    '/images/task-two-placard.png',
    '/images/task-three-placard.png'
  ],
  darkCave: [
    '/images/category-page-placard-1.png',
    '/images/category-page-placard-2.png',
    '/images/category-page-placard-3.png'
  ],
  swamp: [
    '/images/acid-placard-1.png',
    '/images/acid-placard-2.png',
    '/images/acid-placard-3.png'
  ],
  bloodTundra: [
    '/images/blood-placard-1.png',
    '/images/blood-placard-2.png',
    '/images/blood-placard-3.png'
  ],
  palace: [
    '/images/palace-placard-1.png',
    '/images/palace-placard-2.png',
    '/images/palace-placard-3.png'
  ],
  fifthCircle: [
    '/images/fifth-circle-placard-1.png',
    '/images/fifth-circle-placard-2.png',
    '/images/fifth-circle-placard-3.png'
  ]
};

const CategoryTodos = ({
  todos,
  categories,
  handleComplete,
  handleStar,
  handleDelete,
  handleHamburgerClick,
  handleIconClick,
  handleAddTodo,
  showInput,
  setShowInput,
  setNewTodo,
  handleTodoUpdate,
  setCategories,
  handleSelectCategory,
  handleCategoryAdd
}) => {
  const { categoryName } = useParams();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const categoryTodos = todos[categoryName] || [];
  const [newCategoryTodo, setNewCategoryTodo] = useState('');

  const handleAddCategoryTodo = () => {
    if (newCategoryTodo.trim() !== '') {
      const newTodoItem = { text: newCategoryTodo, isCompleted: false, isStarred: false, tags: [categoryName] };
      const updatedTodos = { ...todos, [categoryName]: [...(todos[categoryName] || []), newTodoItem], 'My Day': [...(todos['My Day'] || []), newTodoItem] };
      handleTodoUpdate(updatedTodos);

      const updatedCategories = categories.map(cat =>
        cat.name === categoryName ? { ...cat, count: cat.count + 1 } : cat
      );
      setCategories(updatedCategories);

      setNewCategoryTodo('');
      setShowInput(false);
    }
  };

  const currentPlacardImages = placardImages[theme] || placardImages.default;

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedTodos = Array.from(categoryTodos);
    const [removed] = reorderedTodos.splice(result.source.index, 1);
    reorderedTodos.splice(result.destination.index, 0, removed);
    const updatedTodos = { ...todos, [categoryName]: reorderedTodos };
    handleTodoUpdate(updatedTodos);
  };

  useEffect(() => {
    const category = categories.find(cat => cat.name === categoryName);
    if (!category) {
      navigate('/');
    }
  }, [categoryName, categories, navigate]);

  return (
    <div className="app-container">
      <div className="my-day-container"><h1 className="my-day">{categoryName}</h1></div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="categoryTodos">
          {(provided) => (
            <div className='todo-content-container' {...provided.droppableProps} ref={provided.innerRef}>
              {categoryTodos.map((todo, index) => (
                <Draggable key={index} draggableId={index.toString()} index={index}>
                  {(provided) => (
                    <div 
                      ref={provided.innerRef} 
                      {...provided.draggableProps} 
                      {...provided.dragHandleProps}
                      className="draggable-todo"
                    >
                      <ThemedTodoItem
                        key={index}
                        todo={todo}
                        index={index}
                        handleComplete={() => handleComplete(categoryName, index)}
                        handleStar={() => handleStar(categoryName, index)}
                        handleDelete={() => handleDelete(categoryName, index)} // Pass handleDelete function
                        handleSelectCategory={handleSelectCategory}
                        handleCategoryAdd={handleCategoryAdd}
                        isCategoryPage={true} // Indicate that this is a category page
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <footer className='footer-container'>
        <div className='hamburger-icon' onClick={handleHamburgerClick}>
          <img className='hamburger-icon-image' src="/images/hamburger-sign.png" alt="hamburger-icon-footer-nav" />
        </div>
        <div className='add-todo-icon' onClick={() => handleIconClick(categoryName)}>
          <img className='add-todo-icon-image' src="/images/plus-sign.png" alt="plus-icon-footer-nav" />
        </div>
        <SatanSpeaks />
      </footer>
      {showInput && (
        <div className="todo-input-container">
          <input
            type="text"
            value={newCategoryTodo}
            onChange={(e) => { setNewTodo(e.target.value); setNewCategoryTodo(e.target.value); }}
            className="todo-input"
            placeholder={`Add a new todo to ${categoryName}...`}
          />
          <button onClick={handleAddCategoryTodo} className="add-todo-button">Add</button>
        </div>
      )}
    </div>
  );
};

export default CategoryTodos;
