import './HellNews.css';
import { Link } from 'react-router-dom';

function HellNews() {

    return (
        <Link  to="hell-news-home" className='hell-news-button'>
            Hell News
        </Link>
    )
    
};

export default HellNews;