import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth, firestore } from './firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [displayName, setDisplayName] = useState('User');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (!userDoc.exists()) {
          await setDoc(userDocRef, {
            todos: {},
            categories: [],
            theme: 'default',
            displayName: 'User'
          });
        }
        setCurrentUser({ uid: user.uid, ...userDoc.data() });
        setDisplayName(userDoc.data().displayName || 'User');
      } else {
        setCurrentUser(null);
        setDisplayName('User');
      }
    });
    return unsubscribe;
  }, []);

  const signUp = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const userDocRef = doc(firestore, 'users', user.uid);
      await setDoc(userDocRef, {
        todos: {},
        categories: [],
        theme: 'default',
        displayName: 'User'
      });
    } catch (error) {
      console.error(error);
    }
  };

  const signIn = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error(error);
    }
  };

  const signOutUser = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error(error);
    }
  };

  const updateUserData = async (uid, data) => {
    try {
      const userDocRef = doc(firestore, 'users', uid);
      await updateDoc(userDocRef, data);
    } catch (error) {
      console.error(error);
    }
  };

  const updateDisplayName = async (newName) => {
    if (currentUser) {
      setDisplayName(newName);
      await updateUserData(currentUser.uid, { displayName: newName });
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, signUp, signIn, signOut: signOutUser, updateUserData, displayName, updateDisplayName }}>
      {children}
    </AuthContext.Provider>
  );
};
