import "./SportsWidget.css";
import { useState } from "react";
import TeamPics from "./TeamPics";
import { useSwipeable } from "react-swipeable";

function SportsWidget() {
    const sportsData = [
        { pic: "./images/abyssal-reapers.jpg", teamName: "Abyssal Reapers" },
        { pic: "./images/fiery-imps.jpg", teamName: "Fiery Imps" },
        { pic: "./images/flameclaw-fiends.jpg", teamName: "Flameclaw Fiends" },
        { pic: "./images/hellfire-harbingers.jpg", teamName: "Hellfire Harbingers" },
        { pic: "./images/infernal-titans.jpg", teamName: "Infernal Titans" },
        { pic: "./images/molten-maulers.jpg", teamName: "Molten Maulers" },
        { pic: "./images/searing-shadows.jpg", teamName: "Searing Shadows" },
        { pic: "./images/sulfuric-scorpions.jpg", teamName: "Sulfuric Scorpions" },
        { pic: "./images/blazing-berserkers.jpg", teamName: "Blazing Berserkers" },
        { pic: "./images/crimson-chimera.jpg", teamName: "Crimson Chimera" },
        { pic: "./images/cursed-cerberus.jpg", teamName: "Cursed Cerberus" },
        { pic: "./images/dreadhound-hordes.jpg", teamName: "Dreadhound Hordes" },
        { pic: "./images/pyroclast-punishers.jpg", teamName: "Pyroclast Punishers" },
        { pic: "./images/tormenting-talons.jpg", teamName: "Tormenting Talons" },
        { pic: "./images/ashen-ghouls.jpg", teamName: "Ashen Ghouls" }
    ];

    const getRandomDistinctTeams = () => {
        let firstTeamIndex = Math.floor(Math.random() * sportsData.length);
        let secondTeamIndex;

        // Ensure the second team is different from the first
        do {
            secondTeamIndex = Math.floor(Math.random() * sportsData.length);
        } while (secondTeamIndex === firstTeamIndex);

        return [firstTeamIndex, secondTeamIndex];
    };

    const [currentMatchup, setCurrentMatchup] = useState(getRandomDistinctTeams());

    const handleSwipeLeft = () => {
        setCurrentMatchup(getRandomDistinctTeams()); // Randomly select two distinct teams on swipe left
    };

    const handleSwipeRight = () => {
        setCurrentMatchup(getRandomDistinctTeams()); // Randomly select two distinct teams on swipe right
    };

    const handlers = useSwipeable({
        onSwipedLeft: handleSwipeLeft,
        onSwipedRight: handleSwipeRight,
    });

    const selectedTeam = sportsData[currentMatchup[0]];
    const selectedOppTeam = sportsData[currentMatchup[1]];

    return (
        <div className="teamPic-holder" {...handlers}>
            <TeamPics
                key={currentMatchup.join()} // Unique key to force re-render
                teamPic={selectedTeam.pic}
                teamOppPic={selectedOppTeam.pic}
                teamName={selectedTeam.teamName}
                teamOppName={selectedOppTeam.teamName}
            />
        </div>
    );
}

export default SportsWidget;
