import { useState, useEffect } from "react";

function GameType() {
    const [gameType, setGameType] = useState("")

    useEffect(() => {
        const gameTypesList = [
            "Mutual Slaughterball",
            "Carnage Marathon",
            "Inferno Wrestling",
            "Bloodriver Rowing",
            "Doom Derby",
            "Strategic Beheadings",
            "Organ Eating",
            "Terrestrial Calamities"
        ];

        const randomIndex = Math.floor(Math.random() * gameTypesList.length);
        const randomGameType = gameTypesList[randomIndex];
        setGameType(randomGameType);

    }, []);

    return (
        <div>
            {gameType}
        </div>
    );
}

export default GameType;