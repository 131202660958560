// src/components/WelcomePage/WelcomePage.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './WelcomePage.css';

const WelcomePage = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/');
  };

  return (
    <div className="welcome-page">
      <img src="/welcome-back.jpg" alt="Welcome Back" className="welcome-image" />
      <button className="close-button" onClick={handleClose}>Close</button>
    </div>
  );
};

export default WelcomePage;
