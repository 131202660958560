// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';


    const firebaseConfig = {
        apiKey: "AIzaSyDVqJ7HttoIDV7Jdd-yRaZ-c3R5nSGIK6E",
        authDomain: "satanic-agenda.firebaseapp.com",
        databaseURL: "https://satanic-agenda-default-rtdb.firebaseio.com",
        projectId: "satanic-agenda",
        storageBucket: "satanic-agenda.appspot.com",
        messagingSenderId: "1056746325497",
        appId: "1:1056746325497:web:fecd3c5309feacedfb2928",
        measurementId: "G-PMJJ2QQKJE"
      };

      const app = initializeApp(firebaseConfig);
      const auth = getAuth(app);
      const firestore = getFirestore(app);
      
      export { auth, firestore };