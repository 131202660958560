import React, { useState } from 'react';
import { useTheme } from '../ThemeContext/ThemeContext';
import './ThemedTodoItem.css';

const placardImages = {
  default: [
    '/images/task-one-placard.png',
    '/images/task-two-placard.png',
    '/images/task-three-placard.png'
  ],
  darkCave: [
    '/images/category-page-placard-1.png',
    '/images/category-page-placard-2.png',
    '/images/category-page-placard-3.png'
  ],
  swamp: [
    '/images/acid-placard-1.png',
    '/images/acid-placard-2.png',
    '/images/acid-placard-3.png'
  ],
  bloodTundra: [
    '/images/blood-placard-1.png',
    '/images/blood-placard-2.png',
    '/images/blood-placard-3.png'
  ],
  palace: [
    '/images/palace-placard-1.png',
    '/images/palace-placard-2.png',
    '/images/palace-placard-3.png'
  ],
  fifthCircle: [
    '/images/fifth-circle-placard-1.png',
    '/images/fifth-circle-placard-2.png',
    '/images/fifth-circle-placard-3.png'
  ]
};

const ThemedTodoItem = ({ 
  todo, 
  index, 
  handleComplete, 
  handleStar, 
  handleDelete, 
  handleTag, 
  dropdownIndex, 
  categories, 
  handleSelectCategory, 
  handleCategoryAdd,
  isCategoryPage 
}) => {
  const { theme } = useTheme();
  const [newCategory, setNewCategory] = useState('');
  const currentPlacardImages = placardImages[theme] || placardImages.default;

  const handleCreateCategory = () => {
    if (newCategory.trim() !== '') {
      handleCategoryAdd(newCategory);
      setNewCategory('');
    }
  };

  const getTagIcon = () => {
    if (todo.tags.length > 0) {
      return (
        <div className="tooltip">
          <img
            src="/images/tag-satan-sorted.png"
            alt="tag-sorted-icon"
            className="todo-icon"
          />
          <span className="tooltiptext">{todo.tags.join(', ')}</span>
        </div>
      );
    } else {
      return (
        <img
          src="/images/tag-satan.png"
          alt="tag-icon"
          onClick={() => handleTag(index)}
          className="todo-icon"
        />
      );
    }
  };

  return (
    <div key={index} className={`todo-item ${todo.isStarred ? 'starred' : ''}`} style={{ backgroundImage: `url(${currentPlacardImages[index % 3]})` }}>
      <img
        src={todo.isCompleted ? '/images/circle-check-off-fire.png' : '/images/circle-check-off.png'}
        alt="complete-icon"
        onClick={() => handleComplete(todo.tags.includes('My Day') ? 'My Day' : todo.tags[0], index)}
        className="todo-icon"
      />
      <p className={`todo-text ${todo.isCompleted ? 'completed' : ''}`}>{todo.text}</p>
      {!isCategoryPage && getTagIcon()}
      {dropdownIndex === index && (
        <div className="dropdown-menu">
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            onKeyPress={(e) => { if (e.key === 'Enter') handleCreateCategory(); }}
            className="dropdown-option pulsing-effect"
            placeholder="Create Category"
          />
          {categories.map((category, idx) => (
            <button key={idx} className="dropdown-option" onClick={() => handleSelectCategory(index, category.name)}>
              {category.name}
            </button>
          ))}
        </div>
      )}
      <img
        src={todo.isStarred ? '/images/pentagram-gold.png' : '/images/pentagram-black.png'}
        alt="star-icon"
        onClick={() => handleStar(todo.tags.includes('My Day') ? 'My Day' : todo.tags[0], index)}
        className="todo-icon"
      />
      <img
        src="/images/garbage-black.png"
        alt="delete-icon"
        onClick={() => handleDelete(todo.tags.includes('My Day') ? 'My Day' : todo.tags[0], index)}
        className="todo-icon"
      />
    </div>
  );
};

export default ThemedTodoItem;
