import SatanSpeaks from "../SatanSpeaks/SatanSpeaks";
import "./HellNewsHome.css";
import SportsWidget from "./SportsWidget";
import Stocks from "./Stocks";
  
function HellNewsHome({ handleHamburgerClick }) {
    return (
<div className="app-wrap">
        <div className="big-container">
          <div className="buffer-top"></div>
          <div className="heading-content-container">
            <img className="daily-demon" src="./images/daily-demon-logo.png" alt="daily-demon" />
          </div>
          <div className="sports-and-stocks-container">
            <div className="sports-container">
              <SportsWidget />
            </div>
            <div className="stocks-container">
              <Stocks />
            </div>
          </div>
          <div className="articles-container">
              <div className="featured-article-container"></div>
              <div className="individual-art-container"></div>
            </div> 
        </div>
        <footer className='footer-container'>
        <div className='hamburger-icon' onClick={handleHamburgerClick}>
          <img className='hamburger-icon-image' src="/images/hamburger-sign.png" alt="hamburger-icon-footer-nav" />
        </div>
        <SatanSpeaks />
      </footer>
</div>
    );
}

export default HellNewsHome;