import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ScrollDetail.css';
import SideNav from '../SideNav/SideNav'; // Import SideNav
import SatanSpeaks from '../SatanSpeaks/SatanSpeaks'; // Import SatanSpeaks

const ScrollDetail = ({ scrolls, categories, todos, handleCategoryRename, handleTodoUpdate }) => {
  const { id } = useParams();
  const scroll = scrolls.find(scroll => scroll.id === parseInt(id));
  const [text, setText] = useState('');
  const [showSideNav, setShowSideNav] = useState(false);

  useEffect(() => {
    if (scroll) {
      // Load saved text from localStorage or another storage
      const savedText = localStorage.getItem(`scroll-${scroll.id}-text`) || '';
      setText(savedText);
    }
  }, [scroll]);

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    // Save text to localStorage or another storage
    if (scroll) {
      localStorage.setItem(`scroll-${scroll.id}-text`, newText);
    }
  };

  if (!scroll) {
    return <div>Scroll not found</div>;
  }

  const handleHamburgerClick = () => {
    setShowSideNav(!showSideNav);
  };

  return (
    <div className={`scroll-detail-page paper-${scroll.id}`}>
      <SideNav
        isVisible={showSideNav}
        onClose={() => setShowSideNav(false)}
        categories={categories}
        todos={todos}
        handleCategoryRename={handleCategoryRename}
        onTodoUpdate={handleTodoUpdate}
      />
      <div className="scroll-detail">
        <textarea
          className="scroll-text"
          value={text}
          onChange={handleTextChange}
          placeholder="Start writing here..."
          autoFocus
          style={{ fontFamily: 'Hellmuth', color: 'black' }}
        />
      </div>
      <footer className="footer-container">
        <div className='hamburger-icon' onClick={handleHamburgerClick}>
          <img className='hamburger-icon-image' src="/images/hamburger-sign.png" alt="hamburger-icon-footer-nav" />
        </div>
        <SatanSpeaks />
      </footer>
    </div>
  );
};

export default ScrollDetail;
