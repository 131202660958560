import { useState, useEffect } from "react";
import "./Stocks.css";

function ArrowDisplay() {
    const [showArrow, setShowArrow] = useState("");

    useEffect(() => {
        const arrows = [
            "/images/blood-arrow.png",
            "/images/green-arrow.png"
        ];

        const updateArrow = () => {
            const randomIndex = Math.floor(Math.random() * arrows.length);
            const randomArrow = arrows[randomIndex];
            setShowArrow(randomArrow);
        };

        const getRandomInterval = () => Math.floor(Math.random() * 9000) + 1000; // Random time between 1 and 10 seconds

        updateArrow(); // Initialize with a random arrow
        const interval = setInterval(() => {
            updateArrow();
        }, getRandomInterval());

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    return (
        <div className="arrow-container">
            <img src={showArrow} className="arrow" alt="arrow-stocks" />
        </div>
    );
}

export default ArrowDisplay;
