import React from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '../ThemeContext/ThemeContext';
import './TombstoneContent.css';
import SatanSpeaks from '../SatanSpeaks/SatanSpeaks';

const TombstoneContent = ({ todos, handleTodoUpdate, handleComplete, handleStar, handleDelete, handleHamburgerClick }) => {
  const { theme } = useTheme();
  const { date } = useParams();
  const deletedTodos = todos['Graveyard'] && todos['Graveyard'][date] ? todos['Graveyard'][date] : [];

  return (
    <div className={`app-container ${theme}-theme tombstone-content-page`}>
      <div className="my-day-container">
        <h1 className="my-day-tombstone">Deleted Todos on {new Date(date).toLocaleDateString()}</h1>
      </div>
      <div className="tombstone-content-container">
        {deletedTodos.length > 0 ? (
          deletedTodos.map((todo, index) => (
            <div key={index} className="deleted-todo-item">
              <span className="deleted-todo-text">{todo.text}</span>
            </div>
          ))
        ) : (
          <p>No todos were deleted on this day.</p>
        )}
      </div>
      <footer className='footer-container'>
        <div className='hamburger-icon' onClick={handleHamburgerClick}>
          <img className='hamburger-icon-image' src="/images/hamburger-sign.png" alt="hamburger-icon-footer-nav" />
        </div>
        <SatanSpeaks />
      </footer>
    </div>
  );
};

export default TombstoneContent;
