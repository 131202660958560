import "./TeamPics.css";
import ScoreMaker from "./ScoreMaker";
import GameType from "./GameType";
import GameTime from "./GameTime";
import Stadium from "./Stadium";

function TeamPics({ teamPic, teamOppPic, teamName, teamOppName }) {
    return (
        <div className="team-images-container">
            <div className="team-names">
                <img className="teamPicLeft" src={teamPic} alt={`${teamName} logo`} />
                {teamName}
            </div>
            <div className="center-sports-elements">
                <div className="score">
                    <ScoreMaker />
                </div>
                <div className="game-info">
                    <div className="game-type">
                        <GameType />
                    </div>
                    <div className="arena-pic">
                        <Stadium />
                    </div>
                    <div className="live-now">
                        <img className="live-now-image" src="./images/live-now.gif" alt="live-now-alt" />
                        <GameTime />
                    </div>
                </div>
                <div className="score">
                    <ScoreMaker />
                </div> 
            </div>

            <div className="team-names">
                <img className="teamPic" src={teamOppPic} alt={`${teamOppName} logo`} />
                {teamOppName}
            </div>
        </div>
    );
}

export default TeamPics;
