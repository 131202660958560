import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './CalendarTodos.css';
import ThemedTodoItem from '../ThemedTodoItem/ThemedTodoItem';
import { useTheme } from '../ThemeContext/ThemeContext';
import SatanSpeaks from '../SatanSpeaks/SatanSpeaks';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const CalendarTodos = ({
  todos,
  handleTodoUpdate,
  handleComplete,
  handleStar,
  handleDelete,
  handleHamburgerClick,
  handleIconClick
}) => {
  const { date } = useParams();
  const { theme } = useTheme();
  const [newTodo, setNewTodo] = useState('');
  const [showInput, setShowInput] = useState(false);

  const handleAddTodo = () => {
    if (newTodo.trim() !== '') {
      const newTodoItem = { text: newTodo, isCompleted: false, isStarred: false, tags: [] };
      const updatedTodos = { ...todos, [date]: [...(todos[date] || []), newTodoItem] };
      handleTodoUpdate(updatedTodos);
      setNewTodo('');
      setShowInput(false);
    }
  };

  const formatDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const dateObj = new Date(dateString + 'T00:00:00');
    return dateObj.toLocaleDateString(undefined, options);
  };
  

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedTodos = Array.from(todos[date] || []);
    const [removed] = reorderedTodos.splice(result.source.index, 1);
    reorderedTodos.splice(result.destination.index, 0, removed);
    const updatedTodos = { ...todos, [date]: reorderedTodos };
    handleTodoUpdate(updatedTodos);
  };

  return (
    <div className={`app-container ${theme}-theme`}>
      <div className="my-day-container-2">
        <h1 className="my-day-2">{formatDate(date)}</h1> {/* Fix the date handling */}
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="calendarTodos">
          {(provided) => (
            <div className='todo-content-container' {...provided.droppableProps} ref={provided.innerRef}>
              {(todos[date] || []).map((todo, index) => (
                <Draggable key={index} draggableId={index.toString()} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="draggable-todo"
                    >
                      <ThemedTodoItem
                        key={index}
                        todo={todo}
                        index={index}
                        handleComplete={() => handleComplete(date, index)}
                        handleStar={() => handleStar(date, index)}
                        handleDelete={() => handleDelete(date, index)}
                        handleTag={() => {}}
                        dropdownIndex={null}
                        categories={[]}
                        handleSelectCategory={() => {}}
                        handleCategoryAdd={() => {}}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <footer className='footer-container'>
        <div className='hamburger-icon' onClick={handleHamburgerClick}>
          <img className='hamburger-icon-image' src="/images/hamburger-sign.png" alt="hamburger-icon-footer-nav" />
        </div>
        <div className='add-todo-icon' onClick={() => setShowInput(true)}>
          <img className='add-todo-icon-image' src="/images/plus-sign.png" alt="plus-icon-footer-nav" />
        </div>
        <SatanSpeaks />
      </footer>
      {showInput && (
        <div className="todo-input-container">
          <input
            type="text"
            value={newTodo}
            onChange={(e) => setNewTodo(e.target.value)}
            className="todo-input"
            placeholder="Type your fate..."
          />
          <button onClick={handleAddTodo} className="add-todo-button">Add</button>
        </div>
      )}
    </div>
  );
};

export default CalendarTodos;
